import styled from "styled-components";

export const Headline = styled.h3`
  font-size: 14px;
  font-weight: 600;

  color: #333;
  letter-spacing: 0.5px;
  word-spacing: 2px;
  margin-right: auto;
`;
