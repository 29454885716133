import React from 'react';

import { api } from 'services';
import {
  Information,
  MainCategory,
  News,
  Page,
  Product,
  ShopData,
  // SubCategory,
} from 'types';

// const filterActive = <T extends { active: number }>(
//   items: T[],
//   callback: Function
// ): void => {
//   var result = items.filter((x: T) => x.active === 1);
//   callback(result);
// };

// const filterCategories = (
//   categories: MainCategory[],
//   callback: Function
// ): void => {
//   filterActive(categories, (tmp: any) => {
//     var result = tmp.reduce((acc: MainCategory[], cat: MainCategory) => {
//       const subCats = cat.items.filter((x: SubCategory) => x.active === 1);
//       acc.push({ ...cat, items: subCats });
//       return acc;
//     }, []);
//     callback(result);
//   });
// };

const ShopContext = React.createContext<ShopData | null>(null);

type ShopProviderProps = {
  children: React.ReactNode | React.ReactNode[];
};

export const ShopProvider = ({ children }: ShopProviderProps) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [isError, setIsError] = React.useState<boolean>(false);

  const [info, setInfo] = React.useState<Information | null>(null);
  const [news, setNews] = React.useState<News[]>([]);
  const [pages, setPages] = React.useState<Page[]>([]);

  const [categories, setCategories] = React.useState<MainCategory[]>([]);
  const [products, setProducts] = React.useState<Product[]>([]);

  React.useEffect(() => {
    (async () => {
      const data = await api.fetchDatabase();

      if (data) {
        // filterActive(data.news, setNews);
        setNews(data.news);

        // filterActive(data.pages, setPages);
        setPages(data.pages);

        // filterActive(data.products, setProducts);
        setProducts(data.products);

        // filterCategories(data.categories, setCategories);
        setCategories(data.categories);

        setInfo(data.information);
      } else {
        setIsError(true);
      }

      setIsLoading(false);
    })();
  }, []);

  const value = {
    isLoading,
    isError,
    info,
    news,
    pages,
    categories,
    products,
  };

  return <ShopContext.Provider value={value}>{children}</ShopContext.Provider>;
};

export const useShopContext = () => React.useContext(ShopContext)!;
