import { MainCategory } from "types";
import { useShopContext } from "context";
import { NavigationLink } from "components";

import MenuItem from "./MenuItem";
import { NavContainer } from "./styled";

type Props = {
  closeMenu: Function;
};

const Menu = ({ closeMenu }: Props) => {
  const { categories } = useShopContext();

  return (
    <NavContainer>
      <NavigationLink to="/" onClick={() => closeMenu()}>
        Startseite
      </NavigationLink>

      {categories.map((mainCategory: MainCategory) => (
        <MenuItem
          key={mainCategory.slug}
          mainCategory={mainCategory}
          closeMenu={closeMenu}
        />
      ))}
    </NavContainer>
  );
};

export default Menu;
