import { FormControlLabel, Radio } from "@mui/material";
import { Field } from "formik";

type FormRadioProps = {
  value: string;
  label: {
    title: string;
    description: string;
    info: string;
  };
};

export const FormRadio = ({
  value,
  label: { title, description, info },
}: FormRadioProps) => {
  const text = info.trim();
  return (
    <FormControlLabel
      control={
        <Field as={Radio} type="radio" name="paymentIdx" value={value} />
      }
      label={
        <>
          <h4>{title}</h4>
          <p>{description}</p>
          {text.length > 0 && <p>{text}</p>}
        </>
      }
    />
  );
};
