import Icon from "./Icon";

type Props = {
  size?: string;
};

const MinusIcon = ({ size = "32" }: Props) => {
  return (
    <Icon size={size} strokeWidth="1" fillColor="currentColor">
      <path d="M19 13H5v-2h14v2z"></path>
    </Icon>
  );
};

export default MinusIcon;
