import { Drawer, IconButton, Toolbar } from "@mui/material";

import { CloseIcon } from "components";
import { useGlobalContext } from "context";
import { SideBar } from "..";

import { Headline } from "./styled";

const Menu = () => {
  const {
    menu: { isMenuOpen, closeMenu },
  } = useGlobalContext();

  return (
    <Drawer
      open={isMenuOpen}
      onClose={closeMenu}
      ModalProps={{ keepMounted: true }}
      sx={{ display: { lg: "none" } }}
    >
      <Toolbar disableGutters sx={{ paddingX: 2 }}>
        <Headline>Nähcenter Egelwolf</Headline>
        <IconButton
          size="large"
          edge="end"
          aria-label="Menü schließen"
          onClick={closeMenu}
        >
          <CloseIcon size="20" />
        </IconButton>
      </Toolbar>

      <SideBar />
    </Drawer>
  );
};

export default Menu;
