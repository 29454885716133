import { Card, Fade, Grid, IconButton } from "@mui/material";

import { MyHelmet, CloseIcon } from "components";

import { useMessage } from "./useMessage";

const Message = () => {
  const {
    open,
    message: { title, content },
    closeMessage,
  } = useMessage();

  return (
    <Fade in={open} mountOnEnter unmountOnExit>
      <div>
        <MyHelmet title={title}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            flexDirection="row"
            flexWrap="nowrap"
            paddingRight={1}
            paddingY={1}
            sx={{
              backgroundColor: "error.main",
              color: "#fff",
            }}
            component={Card}
          >
            <Grid item paddingX={2}>
              {content}
            </Grid>

            <Grid item xs="auto">
              <IconButton
                size="large"
                color="inherit"
                aria-label="Nachricht schließen"
                onClick={closeMessage}
              >
                <CloseIcon size="20" />
              </IconButton>
            </Grid>
          </Grid>
        </MyHelmet>
      </div>
    </Fade>
  );
};

export default Message;
