import Icon from "./Icon";

type Props = {
  size?: string;
};

const PlusIcon = ({ size = "32" }: Props) => {
  return (
    <Icon size={size} strokeWidth="1" fillColor="currentColor">
      <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"></path>
    </Icon>
  );
};

export default PlusIcon;
