import { domToReact } from 'html-react-parser';
import { Card, IconButton } from '@mui/material';
import styled from 'styled-components';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';

import {
  CardContainer,
  CardContent,
  CardHead,
  CardImage,
  CartIcon,
  CloseIcon,
  Image,
  Item,
} from 'components';
import { useGlobalContext, useShopContext } from 'context';
import { helper } from 'services';
import React from 'react';
import { Product } from 'types';

const prefix = helper.getUrlPrefix('img');

const ToolTip = styled.abbr`
  cursor: pointer;
  display: inline-flex;
  width: fit-content;
`;

const AdditionalContainer = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
`;

const Gallery = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  justify-content: space-between;
  gap: 16px;
`;

const checkProduct = (url: string): boolean => {
  return url.includes('/produkt') && !url.includes('/info');
};

class ParseService {
  getParseOptions = () => {
    const isProduct = checkProduct(window.location.href);
    return {
      replace: (node: any) => {
        if (node.type === 'tag') {
          switch (node.name) {
            case 'img':
              if (isProduct) return this.getToolTipImage(node);
              return this.getImage(node);
            case 'karte':
              return this.getCard(node);
            case 'galerie':
              return this.getGallery(node);
            case 'video':
              if (!node.attribs?.src) {
                return <React.Fragment></React.Fragment>;
              }

              const src = `${prefix}/abbildungen/${node.attribs?.src}`;
              const type = node.attribs?.type ?? 'video/mp4';

              const attribs = {
                width: node.attribs?.width ?? '320',
                height: node.attribs?.height ?? '240',
                controls: true,
                // controls: ['', 'true'].includes(node.attribs?.controls)
                //   ? true
                //   : false,
                // autoPlay: ['', 'true'].includes(node.attribs?.autoplay)
                //   ? true
                //   : false,
              };

              return (
                <video {...attribs}>
                  <source src={src} type={type}></source>
                </video>
              );
          }
        }
        return node;
      },
    };
  };

  getImage = (node: any): JSX.Element => {
    const { src } = node.attribs;
    return <Image image={src} />;
  };

  getToolTipImage = (node: any): JSX.Element => {
    const {
      popup: { openPopupImage },
    } = useGlobalContext();
    const image = node?.attribs?.src ?? node;

    return (
      <ToolTip title="Bild vergrößern" onClick={() => openPopupImage(image)}>
        <Image image={image} type="product" />
      </ToolTip>
    );
  };

  getCard = (node: any): JSX.Element => {
    const cardHead = (titel?: string, logo?: string) => (
      <CardHead>
        {logo !== undefined && <Image image={logo} type="logo" />}
        <h4>{titel ?? 'Beschreibung'}</h4>
      </CardHead>
    );

    const { bild, titel, logo } = node.attribs;

    if (bild && titel) {
      return (
        <CardContainer>
          <CardImage>
            <Image image={bild} />
          </CardImage>
          <CardContent>
            {cardHead(titel, logo)}
            {domToReact(node.children, this.getParseOptions())}
          </CardContent>
        </CardContainer>
      );
    }

    return (
      <AdditionalContainer>
        {cardHead(titel, logo)}
        {domToReact(node.children, this.getParseOptions())}
      </AdditionalContainer>
    );
  };

  getGallery = (node: any) => {
    const { products } = useShopContext();
    const { kategorie, tag } = node.attribs;
    const filterByCatAndTag = kategorie && tag;
    const prods = filterByCatAndTag
      ? products.filter(
          (x) => x.mainCategory === kategorie && x.subCategory === tag
        )
      : (node.children
          .map((child: any) =>
            products.find((x) => x.slug === child.attribs?.href)
          )
          .filter(Boolean) as Product[]);

    return <ProductGallery products={prods} />;
  };
}

const ProductGallery = ({ products }: { products: Product[] }) => {
  const [open, setOpen] = React.useState(false);
  const [activeProduct, setActiveProduct] = React.useState(products[0]);

  const handleClickOpen = (product: Product) => {
    setActiveProduct(product);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <AdditionalContainer>
      <h4>Varianten</h4>
      <Gallery>
        {products.map((product) => (
          <Button
            key={product.slug}
            variant="text"
            size="small"
            color="inherit"
            sx={{ gap: 0.5 }}
            onClick={() => handleClickOpen(product)}
          >
            <Image image={product.image} type="product" />
            <CartIcon size="20" />
          </Button>
        ))}

        <Dialog
          open={open}
          onClose={handleClose}
          maxWidth="xs"
          fullWidth={true}
        >
          <Item
            product={activeProduct}
            preview={false}
            lightbox={false}
            onAddToCart={handleClose}
          />

          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon size="20" />
          </IconButton>
        </Dialog>
      </Gallery>
    </AdditionalContainer>
  );
};

const parser = new ParseService();

export default parser;
