import React from "react";
import { NavLink } from "react-router-dom";

type Props = {
  children: React.ReactNode;
  to: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
};

const NavigationLink = ({ children, to, onClick }: Props) => {
  return (
    <li>
      <NavLink
        to={to}
        className={({ isActive }) => (isActive ? "active" : "")}
        onClick={onClick}
      >
        {children}
      </NavLink>
    </li>
  );
};

export default NavigationLink;
