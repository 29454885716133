import { File, Opening } from "types";

export const files: File[] = [
  {
    name: "AGB",
    path: "/downloads/AGB.pdf",
  },
  {
    name: "Datenschutz",
    path: "/downloads/Datenschutzerklaerung.pdf",
  },
  {
    name: "Widerrufsrecht",
    path: "/downloads/Widerrufsrecht.pdf",
  },
];

export const days: (keyof Opening)[] = [
  "mo",
  "di",
  "mi",
  "do",
  "fr",
  "sa",
  "so",
];
