import styled from "styled-components";
import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import { Field, FieldHookConfig, useField } from "formik";

export const FormFieldContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 24px;
  column-gap: 16px;
`;

const StyledFormField = styled(TextField)`
  flex: 1 1 45%;
  min-width: 240px;
`;

type FormFieldProps = {
  name: string;
  label: string;
  error: string | undefined;
};

export const FormField = ({ name, label, error }: FormFieldProps) => (
  <Field
    as={StyledFormField}
    name={name}
    label={error ?? label}
    error={!!error}
    variant="outlined"
  />
);

const StyledFormControlLabel = styled(FormControlLabel)`
  display: flex;
  gap: 8px;
  width: fit-content;
  margin: 0;
`;

const StyledFormCheckbox = styled(Checkbox)`
  padding: 0;
`;

type FormCheckboxProps = {
  label: string;
} & FieldHookConfig<any>;

export const FormCheckbox = ({ label, ...props }: FormCheckboxProps) => {
  const [field] = useField(props);
  return (
    <StyledFormControlLabel
      control={<StyledFormCheckbox color="primary" />}
      label={label}
      {...field}
    />
  );
};
