import { Card, Grid } from "@mui/material";

import { MyHelmet } from "components";
import { useShopContext } from "context";

import imageWko from "assets/wk.gif";

// ALTERNATIVER LINK:
// http://firmena-z.wko.at/ecg.asp?mgid=E4F55A062AE256F0D6ABF9C99840944B
const urlWko =
  "https://firmen.wko.at/norbert-egelwolf-n%c3%a4hcenter/wien/?firmaid=a21add1c-c355-42f9-a452-9ad28b481ab1";

const ImpressumPage = () => {
  const shop = useShopContext();

  const { adress, contact } = shop.info!;
  const { name, street, zip } = adress;
  const { phone, phone2, mail } = contact;

  return (
    <MyHelmet title="Impressum">
      <Grid
        container
        flexDirection="column"
        gap={2}
        padding={2}
        component={Card}
      >
        <h3>Impressum</h3>

        <Grid item component="ul">
          <li>
            Telefon: <a href={"tel:" + phone}>{phone}</a>
          </li>
          <li>
            Mail: <a href={"mailto:" + mail}>{mail}</a>
          </li>
        </Grid>

        <Grid item component="ul">
          <li>{name}</li>
          <li>{street}</li>
          <li>{zip}</li>
          <li>Inh. Norbert Egelwolf</li>
        </Grid>

        <Grid
          item
          component="ul"
          sx={{
            "li:last-child": {
              display: "inline-flex",
              marginTop: 2,
            },
          }}
        >
          <li>Wir sind immer für Sie da.</li>
          <li>Außerhalb der Geschäftszeiten erreichen Sie uns unter</li>
          <li>
            Tel.: <a href={"tel:" + phone2}>{phone2}</a>
          </li>
          <li>
            <a href={urlWko} target="_blank" rel="noopener noreferrer">
              <img src={imageWko} alt="WKO - Logo" loading="eager" />
            </a>
          </li>
        </Grid>
      </Grid>
    </MyHelmet>
  );
};

export default ImpressumPage;
