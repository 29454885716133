import { MailData } from "types";
import { helper } from "services";

const axios = require("axios").default;

const prefix = helper.getUrlPrefix("api");

class ApiService {
  fetchDatabase = async () => {
    return await axios
      .get(`${prefix}/shop.min.json?ts=${new Date().getTime()}`)
      .then((res: any) => res.data)
      .catch((err: any) => console.log(err));
  };

  sendMail = async ({ cart, user }: MailData) => {
    const paymentIdx = parseInt(user.paymentIdx);
    const data = {
      cart: cart,
      user: { ...user, paymentIdx },
    };
    return await axios
      .post(`${prefix}/mail/mail.php`, data)
      .then((res: any) => res.data)
      .catch((err: any) => console.log(err));
  };
}

const api = new ApiService();

export default api;
