import React from "react";
import styled from "styled-components";
import { Grid, Card, Button, SlideProps, Slide } from "@mui/material";

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Container = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 16px;
  margin: 0 auto;
  width: 100%;
  max-width: 640px;
`;

export const BackButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  max-width: fit-content;
  line-height: 100%;
  font-size: 10px;
  font-weight: 600;
`;

export const FormHead = styled.h1`
  text-align: center;
  margin-block: 32px;
  font-size: 24px;
  font-weight: 600;
  line-height: 1;
`;

export const FootNote = styled.p`
  font-size: 12px;
  & > li {
    display: inline;
    font-style: italic;
    font-weight: 600;
    margin-inline: 4px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  // margin-top: 32px;
`;

export const FormSlider = styled(Grid)`
  transition: height 500ms ease-in-out;
  position: relative;
  & > * {
    position: absolute;
    width: 100%;
  }
`;

const StyledSlide = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

type FormSlideProps = {
  children: React.ReactNode;
} & Omit<SlideProps, "children">;

export const FormSlide = React.forwardRef(
  ({ children, ...props }: FormSlideProps, ref) => (
    <Slide
      {...props}
      ref={ref}
      timeout={{
        appear: 500,
        enter: 500,
        exit: 250,
      }}
      mountOnEnter
      unmountOnExit
    >
      <StyledSlide>{children}</StyledSlide>
    </Slide>
  )
);
