import React from "react";
import { useNavigate } from "react-router-dom";

import { useShopContext } from "context";
import { PageRedirect } from "types";

const InfoRedirect = () => {
  const navigate = useNavigate();

  const { pages } = useShopContext();

  React.useEffect(() => {
    const page = pages?.[0].slug;

    if (!page) {
      navigate("/", {
        replace: true,
        state: {
          type: "message",
          payload: "page",
        } as PageRedirect,
      });
      return;
    }

    navigate(page, { replace: true });
  }, [pages, navigate]);

  return <></>;
};

export default InfoRedirect;
