import { Drawer, Toolbar, IconButton, Button } from "@mui/material";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

import {
  CloseIcon,
  Image,
  MinusIcon,
  NavigationLink,
  PlusIcon,
  TrashIcon,
} from "components";
import { useCartContext, useGlobalContext } from "context";
import { CartProduct } from "types";
import { helper } from "services";

import {
  NumberContainer,
  Headline,
  NumberText,
  ScrollContainer,
  ItemGrid,
  ItemContainer,
  ItemHead,
  ItemImage,
  ItemButtons,
  ItemQuantity,
} from "./styled";

const Cart = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { cart, numbers, cartAction } = useCartContext();

  const {
    checkoutPath,
    lastPageRef,
    cart: { isCartOpen, closeCart },
  } = useGlobalContext();

  const navigateCart = () => {
    closeCart();

    if (pathname === checkoutPath) return;
    lastPageRef.current = pathname;
    navigate(checkoutPath);
  };

  const updateItem = (slug: string, quantity: number): void =>
    cartAction({
      type: "UPDATE",
      payload: { slug: slug, quantity: quantity },
    });

  const removeItem = (slug: string): void =>
    cartAction({
      type: "REMOVE",
      payload: { slug: slug },
    });

  return (
    <Drawer
      anchor="right"
      open={isCartOpen}
      onClose={closeCart}
      ModalProps={{ keepMounted: true }}
    >
      <Toolbar disableGutters sx={{ paddingX: 2 }}>
        <Headline>Warenkorb</Headline>
        <IconButton
          size="large"
          edge="end"
          aria-label="Warenkorb schließen"
          onClick={closeCart}
        >
          <CloseIcon size="20" />
        </IconButton>
      </Toolbar>

      <ScrollContainer>
        <ItemGrid>
          {cart.length ? (
            cart.map((x: CartProduct) => {
              const price = helper.getPrice(x);

              return (
                <ItemContainer key={x.slug}>
                  <ItemHead>
                    <NavigationLink
                      to={`/produkt/${x.slug}`}
                      onClick={closeCart}
                    >
                      {x.name}
                    </NavigationLink>
                    <span>&agrave; {helper.formatPrice(price)}</span>
                  </ItemHead>

                  <ItemImage>
                    <NavLink to={`/produkt/${x.slug}`} onClick={closeCart}>
                      <Image image={x.image} type="product" />
                    </NavLink>
                  </ItemImage>

                  <ItemButtons>
                    <IconButton
                      size="medium"
                      aria-label="Produkt komplett aus Warenkorb entfernen"
                      onClick={() => removeItem(x.slug)}
                    >
                      <TrashIcon size="20" />
                    </IconButton>
                    <ItemQuantity>
                      <IconButton
                        size="medium"
                        aria-label="Produkt aus dem Warenkorb entfernen"
                        onClick={() => updateItem(x.slug, x.quantity - 1)}
                        disabled={x.quantity === 1}
                      >
                        <MinusIcon size="20" />
                      </IconButton>
                      <span> {x.quantity} </span>
                      <IconButton
                        size="medium"
                        aria-label="Produkt in den Warenkorb hinzufügen"
                        onClick={() => updateItem(x.slug, x.quantity + 1)}
                        disabled={x.quantity === 100}
                      >
                        <PlusIcon size="20" />
                      </IconButton>
                    </ItemQuantity>
                  </ItemButtons>

                  <NumberText>
                    Gesamt
                    <span>{helper.formatTotalPrice(price, x.quantity)}</span>
                  </NumberText>
                </ItemContainer>
              );
            })
          ) : (
            <Headline>Keine Produkte im Warenkorb</Headline>
          )}
        </ItemGrid>
      </ScrollContainer>

      <NumberContainer>
        <NumberText>
          Zwischensumme
          <span>{helper.formatPrice(numbers.subTotal)}</span>
        </NumberText>

        <NumberText>
          Versandkosten
          <span>
            {numbers.shipping ? helper.formatPrice(numbers.shipping) : "Gratis"}
          </span>
        </NumberText>

        <NumberText>
          Gesamtbetrag
          <span>{helper.formatPrice(cart.length ? numbers.total : 0)}</span>
        </NumberText>

        <Button
          fullWidth
          variant="contained"
          size="large"
          aria-label="Bestellung abschließen"
          onClick={navigateCart}
          disabled={!cart.length}
          sx={{ marginTop: 2 }}
        >
          Bestellen
        </Button>
      </NumberContainer>
    </Drawer>
  );
};

export default Cart;
