import styled from "styled-components";

export const Logo = styled.img`
  max-height: 48px;
  margin: 0 auto;

  @media (max-width: 600px) {
    max-height: 40px;
  }
`;

export const Number = styled.span`
  margin-left: 8px;
  font-size: 1.25rem;
`;
