import { Pagination } from "@mui/material";

type Props = {
  currentPage: number;
  totalPages: number;
  setCurrentPage: Function;
};

const MyPagination = ({ currentPage, totalPages, setCurrentPage }: Props) => {
  const handlePagination = (e: React.ChangeEvent<unknown>, value: number) =>
    setCurrentPage(value);

  if (totalPages === 1) return <></>;

  return (
    <Pagination
      page={currentPage}
      count={totalPages}
      onChange={handlePagination}
      size="medium"
      color="primary"
      shape="rounded"
      showFirstButton
      showLastButton
      sx={{
        boxShadow: `
          0px 2px 1px -1px rgb(0 0 0 / 20%),
          0px 1px 1px 0px rgb(0 0 0 / 14%),
          0px 1px 3px 0px rgb(0 0 0 / 12%)
        `,
        backgroundColor: "#fff",
        paddingX: 0.3,
        paddingY: 0.5,
        borderRadius: 1,
        width: "fit-content",
      }}
    />
  );
};

export default MyPagination;
