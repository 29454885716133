import React from "react";

import { useShopContext } from "./shop";
import { cartNumbers, cartUser } from "./constants";
import { helper, storage } from "services";
import {
  CartAction,
  CartData,
  CartNumbers,
  CartProduct,
  CartUser,
  Product,
  UserAction,
} from "types";

type CartInfo = {
  items: { slug: string; quantity: number }[];
  numbers: { subTotal: number; quantity: number };
};

const cartReducer = (
  state: CartProduct[],
  action: CartAction
): CartProduct[] => {
  switch (action.type) {
    case "SET":
      const { products } = action.payload;
      return [...products];
    case "CREATE":
      const { product } = action.payload;
      const createItem = state.find((x) => x.slug === product.slug);
      if (!createItem) return [...state, product];
      createItem.quantity += product.quantity;
      return [...state];
    case "UPDATE":
      const { slug: updateSlug, quantity } = action.payload;
      const updateItem = state.find((x) => x.slug === updateSlug);
      if (updateItem) updateItem.quantity = quantity;
      return [...state];
    case "REMOVE":
      const { slug: removeSlug } = action.payload;
      const removeIndex = state.findIndex((x) => x.slug === removeSlug);
      if (removeIndex !== -1) state.splice(removeIndex, 1);
      return [...state];
    case "CLEAR":
      return [];
    default:
      return state;
  }
};

const userReducer = (state: CartUser, action: UserAction): CartUser => {
  switch (action.type) {
    case "UPDATE":
      const user = action.payload.user;
      storage.write("USER", user);
      return { ...user };
    case "CLEAR":
      storage.write("USER", cartUser);
      return { ...cartUser };
    default:
      return state;
  }
};

const CartContext = React.createContext<CartData | null>(null);

export const CartProvider = ({ children }: { children: React.ReactNode }) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  const [cart, cartAction] = React.useReducer(cartReducer, []);
  const [user, userAction] = React.useReducer(userReducer, cartUser);
  const [numbers, setNumbers] = React.useState<CartNumbers>(cartNumbers);

  const shop = useShopContext();

  const loadCart = React.useCallback(() => {
    const tmpCart: CartProduct[] | null = storage.read("CART");
    if (!tmpCart) return;

    const newCart = tmpCart.reduce<CartProduct[]>((acc, { slug, quantity }) => {
      const prod = shop.products.find((x: Product) => x.slug === slug);
      if (prod) acc.push({ ...prod, quantity });
      return acc;
    }, []);
    cartAction({ type: "SET", payload: { products: newCart } });
  }, [shop.products]);

  const loadUser = React.useCallback(() => {
    const tmpUser = storage.read("USER");
    if (!tmpUser) return;
    userAction({ type: "UPDATE", payload: { user: tmpUser } });
  }, []);

  React.useEffect(() => {
    if (shop.isLoading || shop.isError) return;

    loadCart();
    loadUser();

    setIsLoading(false);
  }, [shop, loadUser, loadCart]);

  React.useEffect(() => {
    if (shop.isLoading || shop.isError) return;

    const { items, numbers } = cart.reduce(
      (acc: CartInfo, { slug, price, discount, quantity }) => {
        acc.items.push({ slug, quantity });
        const money = discount.trim().length ? discount : price;
        acc.numbers.subTotal += helper.calcTotalPrice(money, quantity);
        acc.numbers.quantity += quantity;
        return acc;
      },
      { items: [], numbers: { subTotal: 0.0, quantity: 0 } }
    );

    const { min, cost } = shop.info!.shipping;
    const minPrice = parseFloat(min);
    const shipCost = parseFloat(cost);

    const shipping = numbers.subTotal < minPrice ? shipCost : 0.0;
    const total = numbers.subTotal + shipping;
    const tax = total * 0.1667;

    storage.write("CART", items);
    setNumbers({ shipping, total, tax, ...numbers });
  }, [shop, cart]);

  const value = {
    isLoading,
    cart,
    cartAction,
    numbers,
    user,
    userAction,
  };

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};

export const useCartContext = () => React.useContext(CartContext)!;
