import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';

const LoadingError = ({ isOpen }: { isOpen: boolean }) => {
  return (
    <Dialog open={isOpen}>
      <DialogTitle>Oops! Fehler beim Laden.</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Leider konnten die Inhalte nicht geladen werden.
        </DialogContentText>
        <DialogContentText>
          Bitte kontaktieren Sie uns per eMail unter
        </DialogContentText>
        <DialogContentText>
          <b>office@naehcenter.at</b>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{
            fontWeight: 600,
            fontSize: 14,
            color: '#333',
            textTransform: 'lowercase',
          }}
          size="large"
          aria-label="Email senden"
          href="mailto:office@naehcenter.at"
        >
          office@naehcenter.at
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LoadingError;
