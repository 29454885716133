import Icon from "./Icon";

type Props = {
  size?: string;
};

const ArrowUpIcon = ({ size = "32" }: Props) => {
  return (
    <Icon size={size} strokeWidth="1" fillColor="currentColor">
      <path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z"></path>
    </Icon>
  );
};

export default ArrowUpIcon;
