import React from 'react';
import SelectUnstyled, { SelectProps, selectClasses } from '@mui/base/Select';
import OptionUnstyled, { optionClasses } from '@mui/base/Option';
import PopperUnstyled from '@mui/base/Popper';
import { styled } from '@mui/system';

const colors = {
  light: '#E7EBF0',
  normal: '#CDD2D7',
  dark: '#1A2027',
};

const dropdownWidth = 140;

const StyledButton = styled('button')(
  () => `
    width: ${dropdownWidth}px;
    color: ${colors.dark};
    border: 1px solid ${colors.light};
    height: 40px;
    line-height: 100%;
    font-size: 12px;
    border-radius: 4px;
    padding: 4px 8px 4px 12px;
    margin-left: auto;
    text-align: left;
    background-color: #fff;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
      0px 1px 1px 0px rgb(0 0 0 / 14%),
      0px 1px 3px 0px rgb(0 0 0 / 12%);
    
    &:hover {
      border-color: ${colors.normal};
    }
  
    &.${selectClasses.expanded} {
      &::after {
        content: '▴';
      }
    }
  
    &::after {
      content: '▾';
      float: right;
    }
    `
);

const StyledListbox = styled('ul')(
  () => `
    width: ${dropdownWidth}px;
    color: ${colors.dark};
    border: 1px solid ${colors.normal};
    font-size: 12px;
    padding: 4px;
    margin: 8px 0;
    border-radius: 4px;
    overflow: auto;
    outline: 0px;
    background: #fff;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
      0px 1px 1px 0px rgb(0 0 0 / 14%),
      0px 1px 3px 0px rgb(0 0 0 / 12%);
  `
);

const StyledPopper = styled(PopperUnstyled)`
  z-index: 1;
`;

export const Option = styled(OptionUnstyled)(
  ({ theme }) => `
    list-style: none;
    padding: 8px;
    border-radius: 4px;
    cursor: default;
    
    &:not(:last-of-type) {
      margin-bottom: 4px;
    }
  
    &.${optionClasses.selected} {
      background-color: ${theme.palette.primary.main};
      color: #fff;
    }
  
    &.${optionClasses.highlighted} {
      background-color: ${colors.light};
      color: ${colors.dark};
    }
  
    &.${optionClasses.highlighted}.${optionClasses.selected} {
      background-color: ${theme.palette.primary.main};
      color: #fff;
    }
  
    &.${optionClasses.disabled} {
      color: ${colors.normal};
    }
  
    &:hover:not(.${optionClasses.disabled}) {
      background-color: ${colors.light};
      color: ${colors.dark};
    }
    `
);

export const Select = React.forwardRef(function CustomSelect<
  TValue extends {},
  Multiple extends boolean
>(
  props: SelectProps<TValue, Multiple>,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  const slots = {
    root: StyledButton,
    listbox: StyledListbox,
    popper: StyledPopper,
    ...props.slots,
  };

  return <SelectUnstyled {...props} ref={ref} slots={slots} />;
});
