type StorageType = "CART" | "USER";

class StorageService {
  read = (type: StorageType): any => {
    const itemStr = localStorage.getItem(type);
    if (!itemStr) return null;

    const item = JSON.parse(itemStr);
    const now = new Date();

    if (now.getTime() > item.expiry) {
      localStorage.removeItem(type);
      return null;
    }

    return item.value;
  };

  write = (type: StorageType, jsonObj: object): void => {
    const now = new Date();

    let expiresIn = 1000 * 60 * 1; // default: 1 min
    switch (type) {
      case "CART":
        expiresIn = 1000 * 60 * 60 * 24 * 3; // 3 days
        break;
      case "USER":
        expiresIn = 1000 * 60 * 15; // 15 mins
        break;
    }

    const expiry = now.getTime() + expiresIn;

    const item = {
      value: jsonObj,
      expiry: expiry,
    };

    localStorage.setItem(type, JSON.stringify(item));
  };
}

const storage = new StorageService();

export default storage;
