import React from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery, useTheme } from "@mui/material";
import { FormikHelpers } from "formik";
import useResizeObserver from "use-resize-observer";
import * as yup from "yup";

import { CartUser, PageRedirect } from "types";
import { useCartContext, useGlobalContext } from "context";
import { api } from "services";

const validationSchema = [
  yup.object({
    email: yup.string().email("E-Mail *").required("E-Mail *"),
    phone: yup.string(),

    firstName: yup.string().required("Vorname *"),
    lastName: yup.string().required("Nachname *"),

    street: yup.string().required("Anschrift *"),
    zip: yup.string().required("PLZ *"),
    city: yup.string().required("Ort *"),
    state: yup.string(),

    otherShippingAddress: yup.boolean(),

    firstNameShipping: yup.string(),
    lastNameShipping: yup.string(),

    streetShipping: yup.string(),
    zipShipping: yup.string(),
    cityShipping: yup.string(),
    stateShipping: yup.string(),
  }),

  yup.object({
    paymentIdx: yup.number(),
    notes: yup.string(),
  }),
];

const steps = ["Versand", "Zahlung", "Bestellung"];

type Direction = "right" | "left" | "up" | "down" | undefined;

type DirectionRef = {
  in: Direction;
  out: Direction;
};

export const useCheckout = () => {
  const [activeStep, setActiveStep] = React.useState<number>(0);
  const [activeHeight, setActiveHeight] = React.useState<number>(320);

  const slideRef = React.useRef<HTMLDivElement>();
  const directionRef = React.useRef<DirectionRef>({
    in: "right",
    out: "right",
  });

  const navigate = useNavigate();
  const theme = useTheme();
  const showAlternativeLabel = useMediaQuery(theme.breakpoints.down("sm"));

  const { isLoading, user, userAction, cart, cartAction } = useCartContext();
  const {
    checkoutPath,
    lastPageRef,
    cart: { openCart },
  } = useGlobalContext();

  const currentValidationSchema = validationSchema[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const allStepsDone = activeStep === steps.length;

  useResizeObserver<HTMLDivElement>({
    ref: slideRef.current,
    onResize: ({ height }) => {
      if (!height || height === activeHeight) return;
      setActiveHeight(height);
    },
  });

  const handleRef = (index: number) => {
    if (activeStep !== index) return undefined;
    return (elem: HTMLDivElement) => {
      if (!elem || elem === slideRef.current) return;
      slideRef.current = elem;
      setActiveHeight(elem.clientHeight);
    };
  };

  const handleDirection = (index: number) => {
    if (index === 0) return "right";
    if (allStepsDone) return "left";
    if (activeStep === index) return directionRef.current.in;
    return directionRef.current.out;
  };

  const handleSlide = (index: number) => {
    const goNext = activeStep < index;
    directionRef.current = {
      in: goNext ? "left" : "right",
      out: goNext ? "right" : "left",
    };
    setActiveStep(index);
  };

  const submitForm = async () => {
    const data = { cart, user };
    await api.sendMail(data);

    cartAction({ type: "CLEAR" });
    userAction({ type: "CLEAR" });
  };

  const handleSubmit = async (
    values: CartUser,
    actions: FormikHelpers<CartUser>
  ) => {
    if (isLastStep) {
      await submitForm();
    } else {
      userAction({
        type: "UPDATE",
        payload: { user: values },
      });
    }
    handleSlide(activeStep + 1);
  };

  const handleStepBack = () => handleSlide(activeStep - 1);

  const handlePageBack = () => {
    if (activeStep === steps.length) return navigate("/");

    const lastPage = lastPageRef.current;
    lastPageRef.current = checkoutPath;

    navigate(lastPage ?? "/");
    openCart();
  };

  const handleSlideProps = (index: number) => ({
    in: activeStep === index,
    direction: handleDirection(index),
    ref: handleRef(index),
    appear: false,
  });

  const handleFormikProps = () => ({
    initialValues: user,
    validationSchema: currentValidationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: handleSubmit,
  });

  React.useEffect(() => {
    if (isLoading) return;

    if (!cart.length && activeStep === 0) {
      navigate("/", {
        replace: true,
        state: {
          type: "message",
          payload: "cart",
        } as PageRedirect,
      });
      return;
    }
  }, [isLoading, cart, activeStep, navigate]);

  return {
    form: {
      activeHeight,
      allStepsDone,
    },
    stepper: {
      steps,
      activeStep,
      showAlternativeLabel,
    },
    navigation: {
      handleStepBack,
      handlePageBack,
    },
    props: {
      handleFormikProps,
      handleSlideProps,
    },
  };
};
