import { Modal, IconButton } from "@mui/material";

import { Image, CloseIcon } from "components";
import { useGlobalContext } from "context";

const ImagePopup = () => {
  const {
    popup: { popupImage, closePopupImage },
  } = useGlobalContext();

  const isOpen = popupImage.length > 0;

  if (!isOpen) return <></>;

  return (
    <Modal open={isOpen} onClose={closePopupImage}>
      <>
        <IconButton
          size="large"
          aria-label="Vorschau schließen"
          onClick={closePopupImage}
          sx={{
            position: "absolute",
            zIndex: 9999,
            top: "32px",
            right: "32px",
            backgroundColor: "#fff",
            "&:hover": {
              backgroundColor: "#efefef",
            },
          }}
        >
          <CloseIcon size="20" />
        </IconButton>

        <Image image={popupImage} type="popup" />
      </>
    </Modal>
  );
};

export default ImagePopup;
