import React from "react";
import { useLocation } from "react-router-dom";

interface GlobalData {
  isBrowsing: boolean;
  checkoutPath: string;
  lastPageRef: React.MutableRefObject<string | undefined>;
  menu: {
    isMenuOpen: boolean;
    openMenu: () => void;
    closeMenu: () => void;
  };
  cart: {
    isCartOpen: boolean;
    openCart: () => void;
    closeCart: () => void;
  };
  popup: {
    popupImage: string;
    openPopupImage: (image: string) => void;
    closePopupImage: () => void;
  };
}

const GlobalContext = React.createContext<GlobalData | null>(null);

const checkoutPath = "/warenkorb";

type GlobalProviderProps = {
  children: React.ReactNode | React.ReactNode[];
};

export const GlobalProvider = ({ children }: GlobalProviderProps) => {
  const [isMenuOpen, setIsMenuOpen] = React.useState<boolean>(false);
  const [isCartOpen, setIsCartOpen] = React.useState<boolean>(false);
  const [popupImage, setPopupImage] = React.useState<string>("");

  const lastPageRef = React.useRef<string>();

  const { pathname } = useLocation();
  const isBrowsing = pathname !== checkoutPath;

  const openMenu = () => setIsMenuOpen(true);
  const closeMenu = () => setIsMenuOpen(false);

  const openCart = () => setIsCartOpen(true);
  const closeCart = () => setIsCartOpen(false);

  const openPopupImage = (image: string) => setPopupImage(image);
  const closePopupImage = () => setPopupImage("");

  React.useEffect(() => {
    if (lastPageRef.current === checkoutPath) return;
    closeMenu();
    closeCart();
  }, [pathname]);

  const value = {
    isBrowsing,
    checkoutPath,
    lastPageRef,
    menu: {
      isMenuOpen,
      openMenu,
      closeMenu,
    },
    cart: {
      isCartOpen,
      openCart,
      closeCart,
    },
    popup: {
      popupImage,
      openPopupImage,
      closePopupImage,
    },
  };

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
};

export const useGlobalContext = () => React.useContext(GlobalContext)!;
