import React from "react";
import { Collapse } from "@mui/material";

import { ArrowDownIcon, ArrowUpIcon, NavigationLink } from "components";
import { MainCategory, SubCategory } from "types";

import { NavLinkHead, NavLinkList } from "./styled";

type Props = {
  mainCategory: MainCategory;
  closeMenu: Function;
};

const MenuItem = ({ mainCategory, closeMenu }: Props) => {
  const [open, setOpen] = React.useState(false);

  const toggleOpen = () => setOpen(!open);

  const navigate = () => {
    toggleOpen();
    closeMenu();
  };

  return (
    <>
      <NavLinkHead onClick={toggleOpen}>
        {mainCategory.name}
        {open ? <ArrowUpIcon size="20" /> : <ArrowDownIcon size="20" />}
      </NavLinkHead>

      <Collapse in={open} timeout="auto">
        <NavLinkList>
          {mainCategory.items.map((x: SubCategory) => (
            <NavigationLink
              key={x.slug}
              to={`/kategorie/${mainCategory.slug}/${x.slug}`}
              onClick={navigate}
            >
              {x.name}
            </NavigationLink>
          ))}
        </NavLinkList>
      </Collapse>
    </>
  );
};

export default MenuItem;
