import styled from "styled-components";
import { Card } from "@mui/material";

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Container = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding 16px;
`;

export const PreviewContainer = styled(Container)`
  flex: 1 1 320px;
  min-width: 320px;
  max-width: 480px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;

  h3,
  a {
    width: fit-content;
    font-size: 16px;
    font-weight: 500;
  }

  span {
    color: #666;
    font-size: 12px;
    font-weight: 500;
  }
`;

export const Media = styled.div`
  position: relative;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    max-width: 320px;
    max-height: 320px;
  }
`;

export const PreviewMedia = styled(Media)`
  height: 240px;
`;

export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const QuantityContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  width: 120px;
  background-color: #ededed;
  border: 1px solid #ddd;
  border-radius: 4px;

  span {
    flex: 1 0 auto;
    text-align: center;
    height: 24px;
    line-height: 24px;
    font-size: 12px;
    font-weight: 600;
  }
`;

export const Unit = styled.span`
  font-weight: 500;
  padding-left: 16px;
  padding-right: 8px;
`;

export const Price = styled.div`
  li {
    font-weight: 600;
  }

  li.standard-price {
    font-size: 12px;
    color: #c00;
  }

  span {
    color: #666;
    font-size: 12px;
  }
`;
