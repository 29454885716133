import React from "react";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";

import { SearchIcon } from "components";
import { PageRedirect } from "types";

import { SearchContainer } from "./styled";

type Props = {
  closeMenu: Function;
};

const Search = ({ closeMenu }: Props) => {
  const [text, setText] = React.useState("");
  const navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setText(e.target.value);

  const navigateToSearch = () => {
    const term = text.trim();
    if (term.length) {
      navigate(`/suche?begriff=${term}`);
    } else {
      navigate("/", {
        replace: true,
        state: {
          type: "message",
          payload: "search",
        } as PageRedirect,
      });
    }
    setText("");
    closeMenu();
  };

  return (
    <SearchContainer>
      <input
        type="text"
        placeholder="Suchbegriff"
        autoComplete="off"
        value={text}
        onChange={handleChange}
      />
      <IconButton
        size="large"
        aria-label="Suche starten"
        onClick={navigateToSearch}
      >
        <SearchIcon size="20" />
      </IconButton>
    </SearchContainer>
  );
};

export default Search;
