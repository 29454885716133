// export { default as PersonalData } from "./PersonalData";
// export { default as PaymentMethod } from "./PaymentMethod";
// export { default as Overview } from "./Overview";
// export { default as Message } from "./Message";

import PersonalData from "./PersonalData";
import PaymentMethod from "./PaymentMethod";
import Overview from "./Overview";
import Message from "./Message";

import {
  Container,
  BackButton,
  ButtonContainer,
  FormHead,
  FormSlider,
  FormSlide,
} from "./styled";

export const checkoutPages = {
  PersonalData,
  PaymentMethod,
  Overview,
  Message,
};

export const checkoutComponents = {
  Container,
  BackButton,
  ButtonContainer,
  FormHead,
  FormSlider,
  FormSlide,
};
