import styled from "styled-components";

import { Product } from "types";
import Item from "./Item";

const ItemList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;

type Props = {
  products: Product[];
};

const Items = ({ products }: Props) => {
  return (
    <ItemList>
      {products.map((x) => (
        <Item key={x.slug} product={x} preview />
      ))}
    </ItemList>
  );
};

export default Items;
