import { Product } from 'types';

class HelperService {
  isMobile = (): boolean => {
    return RegExp(
      /Android|webOS|iPhone|iPad|Mac|Macintosh|iPod|BlackBerry|IEMobile|Opera Mini/i
    ).test(navigator.userAgent);
  };

  isWholeNumber = (value: string): boolean => {
    return RegExp(/^\d+$/).test(value);
  };

  getUrlPrefix = (type: 'api' | 'img'): string => {
    const isProduction = process.env.NODE_ENV === 'production';

    if (isProduction) {
      const url = document.baseURI,
        delimiter = '/',
        start = 3,
        tokens = url.split(delimiter).slice(0, start),
        result = tokens.join(delimiter);
      return result;
    }

    switch (type) {
      case 'api':
        return '';
      case 'img':
        return 'https://www.naehcenter.at';
    }
  };

  getPrice = (product: Product): string => {
    return product.discount.trim().length
      ? product.discount.trim()
      : product.price.trim();
  };

  calcTotalPrice = (price: string | number, quantity: number): number => {
    const p = typeof price === 'string' ? parseFloat(price) : price;
    return p * quantity;
  };

  formatPrice = (price: string | number): string => {
    const p = typeof price === 'string' ? parseFloat(price) : price;
    return `${p.toFixed(2)} EURO`;
  };

  formatTotalPrice = (price: string | number, quantity: number): string => {
    const p = this.calcTotalPrice(price, quantity);
    return this.formatPrice(p);
  };
}

const helper = new HelperService();

export default helper;
