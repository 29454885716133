import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import { Providers } from "context";
import { Layout, ScrollToTop } from "components";
import { PageRedirect } from "types";
import {
  HomePage,
  ImpressumPage,
  InfoPage,
  CategoryRedirect,
  CategoryPage,
  ProductPage,
  SearchPage,
  CheckoutPage,
  InfoRedirect,
} from "pages";

import "react-lazy-load-image-component/src/effects/opacity.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import "react-toastify/dist/ReactToastify.min.css";
import { ToastContainer } from "react-toastify";

const App = () => {
  return (
    <BrowserRouter>
      <Providers>
        <ScrollToTop />
        <ToastContainer
          theme="colored"
          autoClose={1000}
          hideProgressBar={false}
          pauseOnHover={true}
          closeOnClick={true}
          draggable={false}
        />
        <Layout>
          <Routes>
            <Route path="/" element={<HomePage />} />

            <Route path="impressum" element={<ImpressumPage />} />

            <Route path="info" element={<InfoRedirect />} />
            <Route path="info/:slug" element={<InfoPage />} />

            <Route path="kategorie/:slug" element={<CategoryRedirect />} />
            <Route path="kategorie/:slug/:slug2" element={<CategoryPage />} />

            <Route path="produkt/:slug" element={<ProductPage />} />

            <Route path="warenkorb" element={<CheckoutPage />} />

            <Route path="suche" element={<SearchPage />} />

            <Route
              path="*"
              element={
                <Navigate
                  to="/"
                  replace={true}
                  state={
                    {
                      type: "message",
                      payload: "page",
                    } as PageRedirect
                  }
                />
              }
            />
          </Routes>
        </Layout>
      </Providers>
    </BrowserRouter>
  );
};

export default App;
