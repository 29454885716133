import React from 'react';
import parse from 'html-react-parser';
import { NavLink } from 'react-router-dom';
import { IconButton } from '@mui/material';
import { toast } from 'react-toastify';

import { Image, CartIcon, MinusIcon, PlusIcon } from 'components';
import { useCartContext } from 'context';
import { Product } from 'types';
import { helper, parser } from 'services';

import {
  Header,
  PreviewContainer,
  Container,
  Media,
  PreviewMedia,
  Price,
  ActionContainer,
  QuantityContainer,
  Unit,
  Layout,
} from './styled';

type Props = {
  product: Product;
  preview?: boolean;
  lightbox?: boolean;
  onAddToCart?: () => void;
};

export const Item = ({ product, preview, lightbox = true, onAddToCart }: Props) => {
  const [quantity, setQuantity] = React.useState<number>(1);

  const { cartAction } = useCartContext();

  const {
    slug,
    name,
    productNumber,
    image,
    discount,
    price,
    unit,
    description,
  } = product;

  const LayoutFragment = preview ? React.Fragment : Layout;
  const Wrapper = preview ? PreviewContainer : Container;
  const MediaWrapper = preview ? PreviewMedia : Media;

  const handleQuantity = (
    type: 'INCREMENT' | 'DECREMENT' | 'UPDATE'
    // value: string = ""
  ) => {
    switch (type) {
      case 'INCREMENT':
        setQuantity((prev) => prev + 1);
        break;
      case 'DECREMENT':
        setQuantity((prev) => prev - 1);
        break;
      //   case "UPDATE":
      //     if (!value.length) {
      //       setQuantity(1);
      //       return;
      //     }

      //     if (!helper.isWholeNumber(value)) {
      //       return;
      //     }

      //     const num = parseInt(value);
      //     if (num > 100) {
      //       setQuantity(100);
      //       return;
      //     }

      //     setQuantity(num);
      //     break;
    }
  };

  const handleCartButton = () => {
    toast.success('Produkt in den Warenkorb gelegt.', {
      position: 'bottom-center',
    });

    cartAction({
      type: 'CREATE',
      payload: { product: { ...product, quantity } },
    });

    setQuantity(1);

    onAddToCart?.();
  };

  const parseOptions = parser.getParseOptions();

  return (
    <LayoutFragment>
      <Wrapper>
        <Header>
          {preview ? (
            <NavLink to={`/produkt/${slug}`}>{name}</NavLink>
          ) : (
            <h3>{name}</h3>
          )}
          <span>Art.-Nr.: {productNumber}</span>
        </Header>

        <MediaWrapper>
          {preview ? (
            <NavLink to={`/produkt/${slug}`} aria-label="Produktseite öffnen">
              <Image image={image} type="product" />
            </NavLink>
          ) : lightbox ? (
            parser.getToolTipImage(image)
          ) : (
            <Image image={image} type="product" />
          )}
        </MediaWrapper>

        <ActionContainer>
          <QuantityContainer>
            <IconButton
              size="medium"
              aria-label="Produkt Anzahl verkleinern"
              onClick={() => handleQuantity('DECREMENT')}
              disabled={quantity === 1}
            >
              <MinusIcon size="20" />
            </IconButton>
            <span>{quantity}</span>
            <IconButton
              size="medium"
              aria-label="Produkt Anzahl vergrößern"
              onClick={() => handleQuantity('INCREMENT')}
              disabled={quantity === 100}
            >
              <PlusIcon size="20" />
            </IconButton>
          </QuantityContainer>
          <Unit>{unit}</Unit>
          <IconButton
            size="large"
            color="inherit"
            aria-label="Produkt zu Warenkorb hinzufügen"
            onClick={handleCartButton}
          >
            <CartIcon size="20" />
          </IconButton>
        </ActionContainer>

        <Price>
          <ul>
            {discount.trim().length ? (
              <>
                <li>{helper.formatPrice(discount)}</li>
                <li className="standard-price">
                  statt {helper.formatPrice(price)}
                </li>
              </>
            ) : (
              <li>{helper.formatPrice(price)}</li>
            )}
          </ul>
          <span>alle Preise inkl. MwSt. zzgl. Versand</span>
        </Price>
      </Wrapper>

      {!preview && parse(description, parseOptions)}
    </LayoutFragment>
  );
};

export default Item;
