import { useSearch } from "hooks";
import {
  MyHelmet,
  Pagination,
  Filter,
  Items,
  FilterContainer,
} from "components";

const SearchPage = () => {
  const { query, products, currentPage, setCurrentPage, totalPages } =
    useSearch();

  return (
    <MyHelmet title="Suche">
      <FilterContainer>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          setCurrentPage={setCurrentPage}
        />
        <Filter />
      </FilterContainer>

      <span>Suchbegriff: {query}</span>

      <Items products={products} />

      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
    </MyHelmet>
  );
};

export default SearchPage;
