import styled from "styled-components";

export const NavContainer = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;

  & > li:first-of-type {
    margin-bottom: 8px;
  }
`;

export const NavLinkHead = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

export const NavLinkList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 8px 12px;
`;

export const SearchContainer = styled.div`
  display: flex;
  justify-content: space-between;

  background-color: rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  width: 100%;
  height: 40px;

  input {
    flex: 1;

    border: none;
    outline: none;
    background-color: transparent;
    padding-left: 12px;
  }

  button {
    width: 40px;
  }
`;

const cardStyles = `
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%),
    0px 1px 3px 0px rgb(0 0 0 / 12%);
`;

export const SideBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  width: 280px;

  ${(props) => (props.theme.card ? cardStyles : ``)}
`;

SideBarContainer.defaultProps = {
  theme: { card: false },
};
