import React from "react";
import {
  ScopedCssBaseline,
  Container,
  useTheme,
  useMediaQuery,
} from "@mui/material";

import {
  LoadingSpinner,
  LoadingError,
  Message,
  ImagePopup,
  AppBar,
  SideBar,
  Menu,
  Cart,
  Header,
  Footer,
} from ".";

import { useGlobalContext, useShopContext } from "context";
import { Content, Wrapper } from "./styled";

type Props = {
  children: React.ReactNode | React.ReactNode[];
};

const Layout = ({ children }: Props) => {
  const { isLoading, isError } = useShopContext();
  const { isBrowsing } = useGlobalContext();

  const theme = useTheme();
  const showSidebar = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <Container maxWidth="lg" disableGutters>
      <ScopedCssBaseline />

      <AppBar />

      <Menu />

      <Cart />

      <ImagePopup />

      {isLoading ? (
        <LoadingSpinner />
      ) : isError ? (
        <LoadingError isOpen={isError} />
      ) : (
        <>
          <Header />

          <Wrapper>
            {isBrowsing && showSidebar && <SideBar card />}
            <Content>
              <Message />

              <Content>{children}</Content>
            </Content>
          </Wrapper>

          {isBrowsing && <Footer />}
        </>
      )}
    </Container>
  );
};

export default Layout;
