import React from "react";
import { useParams, useNavigate } from "react-router-dom";

import { useShopContext } from "context";
import { MainCategory, PageRedirect } from "types";

const CategoryRedirect = () => {
  const { slug } = useParams();
  const navigate = useNavigate();

  const { categories } = useShopContext();

  React.useEffect(() => {
    const mainCategory = categories.find((x: MainCategory) => x.slug === slug);

    if (!mainCategory) {
      navigate("/", {
        replace: true,
        state: {
          type: "message",
          payload: "category",
        } as PageRedirect,
      });
      return;
    }

    const subCategory = mainCategory.items?.[0].slug;

    if (!subCategory) {
      navigate("/", {
        replace: true,
        state: {
          type: "message",
          payload: "category",
        } as PageRedirect,
      });
      return;
    }

    navigate(subCategory, { replace: true });
  }, [categories, slug, navigate]);

  return <></>;
};

export default CategoryRedirect;
