import { CartNumbers, CartUser } from "types";

export const cartNumbers: CartNumbers = {
  quantity: 0,
  subTotal: 0.0,
  total: 0.0,
  tax: 0.0,
  shipping: 0.0,
};

export const cartUser: CartUser = {
  email: "",
  firstName: "",
  lastName: "",
  street: "",
  zip: "",
  city: "",
  state: "",
  phone: "",

  firstNameShipping: "",
  lastNameShipping: "",
  streetShipping: "",
  zipShipping: "",
  cityShipping: "",
  stateShipping: "",
  otherShippingAddress: false,

  notes: "",

  paymentIdx: "0",
};
