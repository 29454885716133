import { useCategory } from "hooks";
import {
  MyHelmet,
  Pagination,
  Filter,
  Items,
  FilterContainer,
} from "components";

export { default as CategoryRedirect } from "./CategoryRedirect";

const CategoryPage = () => {
  const { title, products, currentPage, setCurrentPage, totalPages } =
    useCategory();

  return (
    <MyHelmet title={title}>
      <FilterContainer>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          setCurrentPage={setCurrentPage}
        />
        <Filter />
      </FilterContainer>

      <Items products={products} />

      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
    </MyHelmet>
  );
};

export default CategoryPage;
