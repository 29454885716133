import { Button, CircularProgress } from "@mui/material";
import { useFormikContext } from "formik";

import { useCartContext, useShopContext } from "context";
import { Image, NewTabLink } from "components";
import { helper } from "services";
import { CartUser } from "types";

import {
  ItemGrid,
  ItemContainer,
  ItemHead,
  ItemImage,
  NumberText,
  NumberContainer,
} from "./styled";

import { ButtonContainer, FootNote, Section } from "../styled";

const formatUser = (user: CartUser) => {
  const { otherShippingAddress } = user;
  const phone = user.phone.trim();
  const email = user.email.trim();

  const firstName = user.firstName.trim();
  const lastName = user.lastName.trim();
  const fullName = `${firstName} ${lastName}`;

  const street = user.street.trim();
  const zip = user.zip.trim();
  const city = user.city.trim();
  const state = user.state.trim();
  const location = `${zip} ${city}`;

  const firstNameShipping = user.firstNameShipping.trim();
  const lastNameShipping = user.lastNameShipping.trim();
  const fullNameShipping = `${firstNameShipping} ${lastNameShipping}`;

  const streetShipping = user.streetShipping.trim();
  const zipShipping = user.zipShipping.trim();
  const cityShipping = user.cityShipping.trim();
  const stateShipping = user.stateShipping.trim();
  const locationShipping = `${zipShipping} ${cityShipping}`;

  const contact = { email, phone };
  const name = { fullName, fullNameShipping };
  const address = { street, location, state };
  const otherAddress = {
    otherShippingAddress,
    streetShipping,
    locationShipping,
    stateShipping,
  };

  return { contact, name, address, otherAddress };
};

type Props = {
  goStepBack: (event: React.MouseEvent<HTMLElement>) => void;
};

const Overview = ({ goStepBack }: Props) => {
  const { info } = useShopContext();
  const { user, cart, numbers } = useCartContext();

  const { isSubmitting } = useFormikContext();

  const billing = info!.billing[parseInt(user.paymentIdx)];
  const {
    contact: { email, phone },
    name: { fullName, fullNameShipping },
    address: { street, location, state },
    otherAddress: {
      otherShippingAddress,
      streetShipping,
      locationShipping,
      stateShipping,
    },
  } = formatUser(user);

  return (
    <>
      <Section>
        <h4>Rechnungsadresse</h4>
        <ul>
          <li>{fullName}</li>
          <li>{email}</li>
          {phone && <li>{phone}</li>}
        </ul>
        <ul>
          <li>{street}</li>
          <li>{location}</li>
          {state.length > 0 && <li>{state}</li>}
        </ul>
      </Section>
      <Section>
        <h4>
          Lieferadresse {!otherShippingAddress && "(siehe Rechnungsadresse)"}
        </h4>
        {otherShippingAddress && (
          <ul>
            <li>{fullNameShipping}</li>
            <li>{streetShipping}</li>
            <li>{locationShipping}</li>
            {stateShipping.length > 0 && <li>{stateShipping}</li>}
          </ul>
        )}
      </Section>
      <Section>
        <h4>{billing.title}</h4>
        <ul>
          <li>{billing.description}</li>
          <li>{billing.info}</li>
        </ul>
      </Section>
      {user.notes.trim().length > 0 && (
        <Section>
          <h4>Anmerkungen</h4>
          <span>{user.notes.trim()}</span>
        </Section>
      )}
      <Section>
        <h4>Warenkorb</h4>
        <ItemGrid>
          {cart.map((x) => {
            const price = helper.getPrice(x);

            return (
              <ItemContainer key={x.slug}>
                <ItemHead>
                  {x.name}
                  <span>&agrave; {helper.formatPrice(price)}</span>
                </ItemHead>

                <ItemImage>
                  <Image image={x.image} type="product" />
                </ItemImage>

                <NumberText>
                  <span>Anzahl</span>
                  <span>
                    {x.quantity} {x.unit}
                  </span>
                </NumberText>

                <NumberText>
                  <span>Gesamt</span>
                  <span>{helper.formatTotalPrice(price, x.quantity)}</span>
                </NumberText>
              </ItemContainer>
            );
          })}
        </ItemGrid>

        <NumberContainer>
          <NumberText>
            Zwischensumme
            <span>{helper.formatPrice(numbers.subTotal)}</span>
          </NumberText>

          <NumberText>
            Versandkosten
            <span>
              {numbers.shipping
                ? helper.formatPrice(numbers.shipping)
                : "Gratis"}
            </span>
          </NumberText>

          <NumberText>
            Gesamtbetrag
            <span>{helper.formatPrice(cart.length ? numbers.total : 0)}</span>
          </NumberText>
        </NumberContainer>
      </Section>

      <FootNote>
        Wir verarbeiten Ihre personenbezogenen Daten gemäß der
        <NewTabLink
          to="/downloads/Datenschutzerklaerung.pdf"
          ariaLabel="Datenschutzrichtlinie"
        >
          Datenschutzrichtlinie
        </NewTabLink>
        . <br />
        Wenn Sie fortfahren, stimmen Sie damit den
        <NewTabLink
          to="/downloads/AGB.pdf"
          ariaLabel="Allgemeine Geschäftsbedingungen"
        >
          AGB
        </NewTabLink>
        sowie dem
        <NewTabLink
          to="/downloads/Widerrufsrecht.pdf"
          ariaLabel="Widerrufsrecht"
        >
          Widerrufsrecht
        </NewTabLink>
        zu.
      </FootNote>

      <ButtonContainer>
        <Button variant="contained" onClick={goStepBack}>
          Zurück
        </Button>
        <Button type="submit" variant="contained" disabled={isSubmitting}>
          {isSubmitting ? <CircularProgress size={20} /> : "Kaufen"}
        </Button>
      </ButtonContainer>
    </>
  );
};

export default Overview;
