import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useShopContext } from "context";
import { PageRedirect, Product } from "types";

export const useProduct = () => {
  const [product, setProduct] = React.useState<Product | null>(null);

  const { slug } = useParams();
  const navigate = useNavigate();

  const shop = useShopContext();

  React.useEffect(() => {
    const item = shop.products.find((x: Product) => x.slug === slug);

    if (!item) {
      navigate("/", {
        replace: true,
        state: {
          type: "message",
          payload: "product",
        } as PageRedirect,
      });
      return;
    }

    setProduct(item);
  }, [shop, slug, navigate]);

  return { product };
};
