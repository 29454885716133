import parse from "html-react-parser";

import { MyHelmet } from "components";
import { useInfo } from "hooks";
import { parser } from "services";

export { default as InfoRedirect } from "./InfoRedirect";

const InfoPage = () => {
  const { title, content } = useInfo();

  const parseOptions = parser.getParseOptions();

  return <MyHelmet title={title}>{parse(content, parseOptions)}</MyHelmet>;
};

export default InfoPage;
