import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  gap: 16px;
  padding: 16px;
  min-height: 320px;
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

// import { styled } from "@mui/material";

// export const Wrapper = styled("div")(({ theme: { shadows } }) => ({
//   display: "flex",
//   gap: "16px",
//   padding: "16px",
//   minHeight: "320px",
//   boxShadow: shadows[24],
// }));

// export const Content = styled("div")({
//   flex: 1,
//   display: "flex",
//   flexDirection: "column",
//   gap: "16px",
// });
