import { Button, TextField } from "@mui/material";
import { Field } from "formik";

import { useShopContext } from "context";

import { FormRadio } from "./styled";

import { ButtonContainer, Section } from "../styled";

type Props = {
  goStepBack: (event: React.MouseEvent<HTMLElement>) => void;
};

const PaymentMethod = ({ goStepBack }: Props) => {
  const { info } = useShopContext();

  return (
    <>
      <Section>
        <h4>Zahlungsart</h4>
        {info?.billing.map((item, idx) => (
          <FormRadio key={idx} value={`${idx}`} label={item} />
        ))}
      </Section>

      <Section>
        <h4>Anmerkungen</h4>
        <Field
          fullWidth
          as={TextField}
          name="notes"
          label="Anmerkungen"
          variant="outlined"
          multiline={true}
          minRows={4}
        />
      </Section>

      <ButtonContainer>
        <Button variant="contained" onClick={goStepBack}>
          Zurück
        </Button>
        <Button type="submit" variant="contained">
          Weiter
        </Button>
      </ButtonContainer>
    </>
  );
};

export default PaymentMethod;
