import React from "react";

type MyHelmetProps = {
  children: React.ReactNode | React.ReactNode[];
  title: string;
};

const MyHelmet = ({ children, title }: MyHelmetProps) => {
  React.useEffect(() => {
    document.title = `Nähcenter - ${title}`;
  });

  return <>{children}</>;
};

export default MyHelmet;
