import Icon from "./Icon";

type Props = {
  size?: string;
};

const CartIcon = ({ size = "32" }: Props) => {
  return (
    <Icon size={size} strokeWidth="2" fillColor="none">
      <circle cx="9" cy="21" r="1"></circle>
      <circle cx="20" cy="21" r="1"></circle>
      <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path>
    </Icon>
  );
};

export default CartIcon;
