import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useShopContext } from "context";
import { Page, PageRedirect } from "types";

export const useInfo = () => {
  const [content, setContent] = React.useState<string>("");
  const [title, setTitle] = React.useState<string>("");

  const { slug } = useParams();
  const navigate = useNavigate();

  const shop = useShopContext();

  React.useEffect(() => {
    const item = shop.pages.find((x: Page) => x.slug === slug);

    if (!item) {
      navigate("/", {
        replace: true,
        state: {
          type: "message",
          payload: "page",
        } as PageRedirect,
      });
      return;
    }

    setTitle(item.name);
    setContent(item.description);
  }, [shop, slug, navigate]);

  return { title, content };
};
