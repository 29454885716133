import React from "react";
import ReactDOM from "react-dom";

import App from "./App";

import "./index.scss";

const disableDevTools = (): void => {
  const noop = (): void => undefined;
  const DEV_TOOLS = (window as any).__REACT_DEVTOOLS_GLOBAL_HOOK__;

  if (typeof DEV_TOOLS !== "object") return;

  for (const [key, value] of Object.entries(DEV_TOOLS)) {
    DEV_TOOLS[key] = typeof value === "function" ? noop : null;
  }
};

if (process.env.NODE_ENV && process.env.NODE_ENV === "production") {
  disableDevTools();
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
