import React from "react";
import { Link } from "react-router-dom";

type Props = {
  children: React.ReactNode;
  to: string;
  ariaLabel?: string;
  external?: boolean;
};

const NewTabLink = ({ children, to, external, ariaLabel = "Link" }: Props) => {
  return (
    <li>
      {external ? (
        <a
          href={to}
          aria-label={ariaLabel}
          target="_blank"
          rel="noopener noreferrer"
        >
          {children}
        </a>
      ) : (
        <Link
          to={to}
          aria-label={ariaLabel}
          target="_blank"
          rel="noopener noreferrer"
        >
          {children}
        </Link>
      )}
    </li>
  );
};

export default NewTabLink;
