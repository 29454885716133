import styled from "styled-components";

export const ItemGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  justify-content: space-between;
  gap: 16px;
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;

  border-top: 2px solid #999;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
`;

export const ItemHead = styled.div`
  display: flex;
  flex-direction: column;

  font-size: 14px;
  font-weight: 500;
  color: #333;

  span {
    color: #666;
    font-size: 12px;
    font-weight: 500;
  }
`;

export const ItemImage = styled.div`
  display: flex;
  justify-content: center;
  margin-block: auto;

  img {
    margin-block: 8px;
    max-height: 160px;
  }
`;

export const NumberContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  border-top: 2px solid #ddd;
  border-bottom: 2px solid #ddd;
  background-color: #fafafa;
  margin-top: 16px;
  padding: 8px;
`;

export const NumberText = styled.span`
  display: flex;
  justify-content: space-between;

  color: #666;
  font-size: 12px;
  font-weight: 500;

  &:last-of-type {
    color: #333;
    font-size: 14px;
  }
`;
