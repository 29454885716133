import React from "react";
import parse from "html-react-parser";

import { MyHelmet } from "components";
import { parser } from "services";
import { useShopContext } from "context";

const HomePage = () => {
  const { news } = useShopContext();

  const parseOptions = parser.getParseOptions();

  return (
    <MyHelmet title="Startseite">
      {news.map((item, idx) => (
        <React.Fragment key={idx}>
          {parse(item.description, parseOptions)}
        </React.Fragment>
      ))}
    </MyHelmet>
  );
};

export default HomePage;
