import { AppBar, IconButton, Toolbar, Tooltip } from "@mui/material";

import { CartIcon, MenuIcon } from "components";
import { useCartContext, useGlobalContext } from "context";

import { Logo, Number } from "./styled";

import imageLogo from "assets/logo.jpg";

const MyAppBar = () => {
  const { numbers } = useCartContext();

  const {
    isBrowsing,
    menu: { openMenu },
    cart: { openCart },
  } = useGlobalContext();

  return (
    <AppBar position="sticky">
      <Toolbar>
        {isBrowsing && (
          <Tooltip title="Menü öffnen" sx={{ display: { lg: "none" } }}>
            <IconButton
              size="large"
              color="inherit"
              aria-label="Menü öffnen"
              edge="start"
              onClick={openMenu}
            >
              <MenuIcon size="20" />
            </IconButton>
          </Tooltip>
        )}
        <Logo src={imageLogo} alt="Nähcenter Egelwolf - Logo" />
        {isBrowsing && (
          <Tooltip title="Warenkorb öffnen">
            <IconButton
              size="large"
              color="inherit"
              aria-label="Warenkorb öffnen"
              edge="end"
              onClick={openCart}
            >
              <CartIcon size="20" />
              <Number>{numbers.quantity}</Number>
            </IconButton>
          </Tooltip>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default MyAppBar;
