import { useGlobalContext } from "context";

import Search from "./Search";
import Menu from "./Menu";

import { SideBarContainer } from "./styled";

type Props = {
  card?: boolean | undefined;
};

const SideBar = ({ card }: Props) => {
  const {
    menu: { closeMenu },
  } = useGlobalContext();

  return (
    <SideBarContainer theme={{ card: Boolean(card) }}>
      <Search closeMenu={closeMenu} />
      <Menu closeMenu={closeMenu} />
    </SideBarContainer>
  );
};

export default SideBar;
