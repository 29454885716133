import React from "react";
import { ThemeProvider, createTheme } from "@mui/material";

import { ShopProvider, CartProvider, GlobalProvider } from ".";

const theme = createTheme({
  palette: {
    primary: {
      main: "#FE0000",
    },
  },
});

type Props = { children: React.ReactNode };

export const Providers = ({ children }: Props) => {
  return (
    <ThemeProvider theme={theme}>
      <ShopProvider>
        <CartProvider>
          <GlobalProvider>{children}</GlobalProvider>
        </CartProvider>
      </ShopProvider>
    </ThemeProvider>
  );
};
