type Props = {
  children: React.ReactNode | React.ReactNode[];
  size?: string;
  strokeWidth?: string;
  fillColor?: string;
  viewBox?: string;
};

const Icon = ({
  children,
  size = "32",
  strokeWidth = "1",
  fillColor = "none",
  viewBox = "0 0 24 24",
}: Props) => {
  return (
    <svg
      stroke="currentColor"
      fill={fillColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={strokeWidth}
      viewBox={viewBox}
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      {children}
    </svg>
  );
};

export default Icon;
