import { LazyLoadImage } from "react-lazy-load-image-component";

import { helper } from "services";

type ImageType = "default" | "logo" | "product" | "popup";

type Props = {
  image: string;
  type?: ImageType;
};

const styles: { [K in ImageType]: any } = {
  default: {},
  logo: { height: 32 },
  product: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  popup: {
    maxWidth: "80%",
    maxHeight: "80%",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
  },
};

// const sizes: { [K in ImageType]: any } = {
//   default: "&w=1024",
//   logo: "&h=64",
//   product: "&w=512",
//   popup: "&w=1024",
// };

const prefix = helper.getUrlPrefix("img");

const Image = ({ image, type = "default" }: Props) => {
  const src = `${prefix}/abbildungen/${image}`;

  // let src;
  // if (image.includes(".gif")) {
  //   src = `${prefix}/abbildungen/${image}`;
  // } else {
  //   src = `https://images.weserv.nl/?url=${prefix}/abbildungen/${image}${sizes[type]}&output=webp&we`;
  // }

  return (
    <LazyLoadImage
      src={src}
      alt={image}
      effect="blur"
      wrapperProps={{ style: styles[type] }}
      style={{ objectFit: "contain" }}
    />
  );
};

export default Image;
