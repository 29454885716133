import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { PageNotFound, PageRedirect } from "types";

import { messages } from "./constants";

interface State {
  open: boolean;
  key: string;
  type: PageNotFound;
}

type Action =
  | { type: "OPEN"; payload: { key: string; type: PageNotFound } }
  | { type: "CLOSE" };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "OPEN":
      return { ...action.payload, open: true };
    case "CLOSE":
      return { ...state, open: false };
    default:
      return state;
  }
};

const values: State = {
  open: false,
  key: "",
  type: "page",
};

export const useMessage = () => {
  const [{ open, key, type }, errorAction] = React.useReducer(reducer, values);

  const { state, key: locationKey } = useLocation();
  const navigate = useNavigate();

  const message = messages[type];

  const closeMessage = React.useCallback(() => {
    navigate("/", { replace: true });
  }, [navigate]);

  React.useEffect(() => {
    if (key === locationKey) return;

    const info = state as PageRedirect | null;
    if (info && info.type === "message") {
      return errorAction({
        type: "OPEN",
        payload: {
          key: key,
          type: info.payload,
        },
      });
    }

    errorAction({ type: "CLOSE" });
  }, [locationKey, state, key, type, closeMessage]);

  return { open, message, closeMessage };
};
