import { useLocation } from "react-router-dom";

import { useShopContext } from "context";
import { NavigationLink } from "components";
import { Page } from "types";

import { HeaderContainer, Brands, NavContainer } from "./styled";

import imageMarken from "assets/marken.png";

const Header = () => {
  const { pathname } = useLocation();
  const isBrowsing = pathname !== "/warenkorb";

  const { pages } = useShopContext();

  return (
    <HeaderContainer>
      <Brands src={imageMarken} alt="Nähcenter Marken" loading="eager" />

      {isBrowsing && pages.length && (
        <NavContainer>
          <NavigationLink to="/">Startseite</NavigationLink>

          {pages.map((page: Page) => (
            <NavigationLink key={page.slug} to={`/info/${page.slug}`}>
              {page.name}
            </NavigationLink>
          ))}
        </NavContainer>
      )}
    </HeaderContainer>
  );
};

export default Header;
