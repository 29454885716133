import styled from "styled-components";
import { Card } from "@mui/material";

export const CardContainer = styled(Card)`
  display: flex;
  flex-wrap: wrap-reverse;

  &:nth-of-type(even) {
    flex-direction: row-reverse;
  }

  @media (min-width: 900px) {
    flex-wrap: nowrap;
  }
`;

export const CardImage = styled.div`
  min-width: 100%;

  @media (min-width: 900px) {
    min-width: 50%;
  }
`;

export const CardContent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 8px;

  min-width: 100%;
  padding: 24px;

  @media (min-width: 900px) {
    min-width: 50%;
  }
`;

export const CardHead = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const FilterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;
