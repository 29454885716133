import { Form, Formik } from "formik";
import { Stepper, Step, StepLabel } from "@mui/material";

import { useCheckout } from "hooks";
import {
  ArrowLeftIcon,
  MyHelmet,
  checkoutPages,
  checkoutComponents,
} from "components";
import { useCartContext } from "context";

const { PersonalData, PaymentMethod, Overview, Message } = checkoutPages;
const { Container, BackButton, FormHead, FormSlider, FormSlide } =
  checkoutComponents;

const CheckoutPage = () => {
  const { isLoading } = useCartContext();
  const {
    form: { activeHeight, allStepsDone },
    stepper: { steps, activeStep, showAlternativeLabel },
    navigation: { handleStepBack, handlePageBack },
    props: { handleFormikProps, handleSlideProps },
  } = useCheckout();

  const backButtonText = `Zurück zum ${allStepsDone ? "Shop" : "Warenkorb"}`;

  if (isLoading) return <></>;

  return (
    <MyHelmet title="Warenkorb">
      <Container>
        <BackButton color="inherit" size="large" onClick={handlePageBack}>
          <ArrowLeftIcon size="14" /> {backButtonText}
        </BackButton>

        <FormHead>Bestellformular</FormHead>

        <Stepper
          activeStep={activeStep}
          orientation="horizontal"
          alternativeLabel={showAlternativeLabel}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        <Formik {...handleFormikProps()}>
          {() => (
            <Form>
              <FormSlider sx={{ height: `${activeHeight}px` }}>
                <FormSlide {...handleSlideProps(0)}>
                  <PersonalData />
                </FormSlide>
                <FormSlide {...handleSlideProps(1)}>
                  <PaymentMethod goStepBack={handleStepBack} />
                </FormSlide>
                <FormSlide {...handleSlideProps(2)}>
                  <Overview goStepBack={handleStepBack} />
                </FormSlide>
                <FormSlide {...handleSlideProps(3)}>
                  <Message />
                </FormSlide>
              </FormSlider>
            </Form>
          )}
        </Formik>
      </Container>
    </MyHelmet>
  );
};

export default CheckoutPage;
