import { PageNotFound } from "types";

export const messages: {
  [key in PageNotFound]: { title: string; content: string };
} = {
  page: {
    title: "Seite nicht gefunden",
    content: "Die Seite wurde nicht gefunden...",
  },
  category: {
    title: "Kategorie nicht verfügbar",
    content: "Aktuell ist diese Kategorie nicht verfügbar...",
  },
  categoryEmpty: {
    title: "Keine Produkte verfügbar",
    content: "Aktuell sind in dieser Kategorie keine Produkte verfügbar...",
  },
  product: {
    title: "Produkt nicht verfügbar",
    content: "Aktuell ist dieses Produkt nicht verfügbar...",
  },
  search: {
    title: "Kein Suchbegriff",
    content:
      "Zur Suche muss ein Begriff in der Suchleiste eingegeben werden...",
  },
  searchTerm: {
    title: "Suchbegriff nicht gefunden",
    content:
      "Leider haben wir für Ihre Suche keinen passenden Treffer gefunden. Bitte suchen Sie mit einem anderen Begriff...",
  },
  cart: {
    title: "Warenkorb ist leer",
    content: "Aktuell ist der Warenkorb leer...",
  },
};
