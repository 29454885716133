import Icon from "./Icon";

type Props = {
  size?: string;
};

const MenuIcon = ({ size = "32" }: Props) => {
  return (
    <Icon size={size} strokeWidth="1" fillColor="currentColor">
      <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path>
    </Icon>
  );
};

export default MenuIcon;
