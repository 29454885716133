import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';

import { Select, Option } from './styled';

type Sorting = '' | 'preis-auf' | 'preis-ab';

const sortTypes: Sorting[] = ['', 'preis-auf', 'preis-ab'];

const sortArray: {
  [S in Sorting]: string;
} = {
  '': 'Beste Ergebnisse',
  'preis-auf': 'Preis aufsteigend',
  'preis-ab': 'Preis absteigend',
};

const Filter = () => {
  const [sortType, setSortType] = React.useState<Sorting>('');

  const { search, pathname } = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {
    const { sort } = queryString.parse(search);

    if (sort && sortTypes.includes(sort as Sorting)) {
      setSortType(sort as Sorting);
    } else {
      setSortType('');
    }
  }, [search]);

  const setSortFilter = (value: string) => {
    if (value === sortType) return;

    const { begriff } = queryString.parse(search);

    const paramsArray: string[] = [];
    if (begriff) paramsArray.push('begriff=' + begriff);
    if (value.length) paramsArray.push('sort=' + value);

    const params = paramsArray.join('&');
    const path = `${pathname}?${params}`;

    setSortType(value as Sorting);
    navigate(path, { replace: true });
  };

  return (
    <Select
      value={sortType}
      onChange={(_: any, value: any) => setSortFilter(value!)}
    >
      {sortTypes.map((type: Sorting, idx: number) => (
        <Option key={idx} value={type}>
          {sortArray[type]}
        </Option>
      ))}
    </Select>
  );
};

export default Filter;
