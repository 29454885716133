import { useProduct } from "hooks";
import { MyHelmet, Item } from "components";

const ProductPage = () => {
  const { product } = useProduct();

  if (!product) return <></>;

  return (
    <MyHelmet title={product.name}>
      <Item product={product} />
    </MyHelmet>
  );
};

export default ProductPage;
