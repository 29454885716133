import Icon from "./Icon";

type Props = {
  size?: string;
};

const ArrowDownIcon = ({ size = "32" }: Props) => {
  return (
    <Icon size={size} strokeWidth="1" fillColor="currentColor">
      <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path>
    </Icon>
  );
};

export default ArrowDownIcon;
