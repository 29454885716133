import styled from "styled-components";

const drawerWidth = 280;

export const Headline = styled.h3`
  font-size: 14px;
  font-weight: 600;
  color: #333;
  margin-right: auto;
`;

export const ScrollContainer = styled.div`
  width: ${drawerWidth}px;
  height: 100%;
  padding: 4px 16px 12px;
  overflow: hidden auto;
`;

export const ItemGrid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  &:not(:last-of-type) {
    padding-bottom: 16px;
    border-bottom: 2px solid #ededed;
  }
`;

export const ItemHead = styled.div`
  display: flex;
  flex-direction: column;

  a {
    font-size: 14px;
    font-weight: 500;
    color: #333;
  }

  span {
    color: #666;
    font-size: 12px;
    font-weight: 500;
  }
`;

export const ItemImage = styled.div`
  display: flex;
  justify-content: center;

  img {
    margin: 4px 0;
    max-width: ${drawerWidth - 48}px;
    max-height: ${drawerWidth / 2}px;
  }
`;

export const ItemButtons = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ItemQuantity = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  background-color: #ededed;
  border: 1px solid #ddd;
  border-radius: 4px;

  span {
    height: 24px;
    line-height: 24px;
    font-size: 12px;
    font-weight: 600;
  }
`;

export const NumberContainer = styled.div`
  position: sticky;
  bottom: 0;

  width: ${drawerWidth}px;
  padding: 16px;
  border-top: 2px solid #ddd;
  background-color: #fafafa;
`;

export const NumberText = styled.span`
  display: flex;
  justify-content: space-between;

  &:last-of-type {
    font-weight: 600;
  }
`;
