import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";

import { MessageContainer } from "./styled";

const Message = () => {
  const navigate = useNavigate();
  const returnToShop = () => navigate("/");

  return (
    <MessageContainer>
      <b>
        Vielen Dank für Ihre Bestellung.
        <br />
        <br />
        Bitte überprüfen Sie jetzt Ihren Posteingang. Sie haben per E-Mail eine
        Bestätigung der Bestellung an die angegebene E-Mail-Adresse erhalten.
        <br />
        <br />
        Dennoch kann es zu einem Übertragungsfehler kommen - daher erhalten Sie
        in den nächsten 24 Stunden eine Info bezüglich Lieferstatus /
        Versandstatus.
        <br />
        <br />
        Sollten Sie diese nicht erhalten oder haben Rückfragen zu Ihrer
        Bestellung, bitte melden Sie sich persönlich per E-Mail oder Telefon.
      </b>
      <Button variant="contained" onClick={returnToShop}>
        Zurück zum Shop
      </Button>
    </MessageContainer>
  );
};

export default Message;
