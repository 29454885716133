import { FacebookIcon, NavigationLink, NewTabLink } from "components";
import { useShopContext } from "context";

import { helper } from "services";

import { days, files } from "./constants";

import {
  FooterContainer,
  LinkList,
  UpperContainer,
  UpperGrid,
  UpperGridItem,
  TimeList,
  LowerContainer,
  Creator,
} from "./styled";

const facebook = helper.isMobile()
  ? "https://m.facebook.com/NaehcenterEgelwolf/"
  : "https://www.facebook.com/NaehcenterEgelwolf/";

const Footer = () => {
  const { info } = useShopContext();

  const { adress, contact, opening } = info!;
  const { name, street, zip } = adress;
  const { phone, mail } = contact;

  return (
    <FooterContainer>
      <UpperContainer>
        <UpperGrid>
          <UpperGridItem>
            <h3>Haben Sie Fragen?</h3>

            <div>
              <h4>Schauen Sie gerne bei uns vorbei</h4>
              <ul>
                <li>{name}</li>
                <li>{street}</li>
                <li>{zip}</li>
              </ul>
            </div>

            <div>
              <h4>Oder kontaktieren Sie uns</h4>
              <ul>
                <li>
                  Telefon: <a href={"tel:" + phone}>{phone}</a>
                </li>
                <li>
                  Mail: <a href={"mailto:" + mail}>{mail}</a>
                </li>
              </ul>
            </div>
          </UpperGridItem>

          <UpperGridItem>
            <h3>Öffnungszeiten</h3>
            <TimeList>
              {days.map((day) => {
                const time = opening[day].trim();
                if (!time.length) return null;
                return (
                  <li key={day}>
                    <span>{day.toUpperCase()}</span> {time}
                  </li>
                );
              })}
            </TimeList>
          </UpperGridItem>
        </UpperGrid>

        <LinkList>
          <NewTabLink to={facebook} ariaLabel="Facebook" external>
            <FacebookIcon size="32" />
          </NewTabLink>

          {files.map((file) => (
            <NewTabLink key={file.path} to={file.path} ariaLabel={file.name}>
              {file.name}
            </NewTabLink>
          ))}

          <NavigationLink to="/impressum">Impressum</NavigationLink>
        </LinkList>
      </UpperContainer>

      <LowerContainer>
        <div>
          <b>Versand</b>
          <p>
            Innerhalb von Österreich ist die Lieferung ab 30.00 EUR
            versandkostenfrei.
            <br />
            Die Versandkosten für die EU richten sich nach den üblichen
            Versandtarifen.
          </p>
        </div>
        <div>
          <b>Haftung</b>
          <p>
            Wir entwickeln die Inhalte dieser Webseite ständig weiter und
            bemühen uns, korrekte und aktuelle Informationen bereitzustellen.
            <br />
            Leider können wir keine Haftung für die Korrektheit aller Inhalte
            auf dieser Webseite übernehmen, speziell für jene, die seitens
            Dritter bereitgestellt werden.
            <br />
            Sollten Ihnen falsche Inhalte oder Fehler auffallen, bitten wir Sie
            uns zu kontaktieren.
          </p>
        </div>
        <Creator>
          Erstellt von{" "}
          <a href="mailto:frank.kolesik@gmail.com">Frank Kolesik</a>
        </Creator>
      </LowerContainer>
    </FooterContainer>
  );
};

export default Footer;
