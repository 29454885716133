import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import queryString from 'query-string';

import { useShopContext } from 'context';
import { MainCategory, PageRedirect, Product, SubCategory } from 'types';

const itemsPerPage = 12;

export const useCategory = () => {
  const [resultsAll, setResultsAll] = React.useState<Product[]>([]);
  const [results, setResults] = React.useState<Product[]>([]);
  const [products, setProducts] = React.useState<Product[]>([]);

  const [title, setTitle] = React.useState<string>('');
  const [category, setCategory] = React.useState<MainCategory | null>(null);

  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [totalPages, setTotalPages] = React.useState<number>(1);

  const { slug, slug2 } = useParams();
  const { search } = useLocation();
  const navigate = useNavigate();

  const shop = useShopContext();

  React.useEffect(() => {
    const mainCategory = shop.categories.find(
      (x: MainCategory) => x.slug === slug
    );

    if (!mainCategory) {
      navigate('/', {
        replace: true,
        state: {
          type: 'message',
          payload: 'category',
        } as PageRedirect,
      });
      return;
    }

    const items = shop.products.filter((x: Product) => x.mainCategory === slug);

    if (!items.length) {
      navigate('/', {
        replace: true,
        state: {
          type: 'message',
          payload: 'categoryEmpty',
        } as PageRedirect,
      });
      return;
    }

    setCategory(mainCategory);
    setTitle(mainCategory.name);
    setResultsAll(items);
  }, [shop, slug, navigate]);

  React.useEffect(() => {
    if (!category || category.slug !== slug) return;

    const subCategory = category.items.find(
      (x: SubCategory) => x.slug === slug2
    );

    if (!subCategory) {
      navigate('/', {
        replace: true,
        state: {
          type: 'message',
          payload: 'category',
        } as PageRedirect,
      });
      return;
    }

    const { sort } = queryString.parse(search);
    var items = [...resultsAll];

    if (slug2) {
      items = items.filter((x) => x.subCategory === slug2);
    }

    if (!items.length) {
      navigate('/', {
        replace: true,
        state: {
          type: 'message',
          payload: 'categoryEmpty',
        } as PageRedirect,
      });
      return;
    }

    if (sort) {
      const s = sort === 'preis-ab' ? -1 : 1;
      items.sort((x: Product, y: Product) => {
        return s * (parseFloat(x.price) - parseFloat(y.price));
      });
    }

    setResults([...items]);
    setCurrentPage(1);
    setTotalPages(Math.ceil(items.length / itemsPerPage));
  }, [resultsAll, category, slug, slug2, search, navigate]);

  React.useEffect(() => {
    const idxLast = currentPage * itemsPerPage;
    const idxFirst = idxLast - itemsPerPage;
    const items = results.slice(idxFirst, idxLast);
    setProducts([...items]);
    window.scrollTo(0, 0);
  }, [results, currentPage]);

  return { title, products, currentPage, setCurrentPage, totalPages };
};
