import styled from "styled-components";

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 8px;
`;

export const UpperContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 48px;
  padding: 48px;

  background-color: #ececec;
  border-top: 4px solid #333;
`;

export const UpperGrid = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 48px;
`;

export const UpperGridItem = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const TimeList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;

  span {
    display: inline-block;
    width: 32px;
  }
`;

export const LinkList = styled.ul`
  display: flex;
  justify-content: center;
  alig-items: center;
  flex-wrap: wrap;
  column-gap: 32px;
  row-gap: 8px;
`;

export const LowerContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 48px;
  padding: 48px;

  background-color: #21262c;
  border-top: 4px solid #000;

  color: #aaa;
  font-size: 12px;

  b {
    color: #ccc;
    font-weight: 500;
  }
`;

export const Creator = styled.p`
  text-align: right;

  a {
    color: #ccc;
    font-weight: 600;
  }

  a:hover {
    color: #fff;
  }
`;
