import Icon from "./Icon";

type Props = {
  size?: string;
};

const ArrowLeftIcon = ({ size = "32" }: Props) => {
  return (
    <Icon size={size} strokeWidth="1" fillColor="currentColor">
      <path d="M17.77 3.77 16 2 6 12l10 10 1.77-1.77L9.54 12z"></path>
    </Icon>
  );
};

export default ArrowLeftIcon;
