import { useFormikContext } from "formik";

import { CartUser } from "types";

import { FormField, FormCheckbox, FormFieldContainer } from "./styled";

import { ButtonContainer, Section } from "../styled";
import { Button } from "@mui/material";

type FieldType = {
  name: keyof CartUser;
  label: string;
};

const personFields: FieldType[] = [
  {
    name: "email",
    label: "E-Mail *",
  },
  {
    name: "phone",
    label: "Telefon",
  },
  {
    name: "firstName",
    label: "Vorname *",
  },
  {
    name: "lastName",
    label: "Nachname *",
  },
  {
    name: "street",
    label: "Anschrift *",
  },
  {
    name: "zip",
    label: "PLZ *",
  },
  {
    name: "city",
    label: "Ort *",
  },
  {
    name: "state",
    label: "Land",
  },
];

const otherFields: FieldType[] = [
  {
    name: "firstNameShipping",
    label: "Vorname",
  },
  {
    name: "lastNameShipping",
    label: "Nachname",
  },
  {
    name: "streetShipping",
    label: "Anschrift",
  },
  {
    name: "zipShipping",
    label: "PLZ",
  },
  {
    name: "cityShipping",
    label: "Ort",
  },
  {
    name: "stateShipping",
    label: "Land",
  },
];

const PersonalData = () => {
  const {
    values: { otherShippingAddress },
    errors,
  } = useFormikContext<CartUser>();

  const getErrorText = (key: keyof CartUser) => errors[key];

  return (
    <>
      <Section>
        <h4>Rechnungsadresse</h4>
        <FormFieldContainer>
          {personFields.map((field) => (
            <FormField
              key={field.name}
              {...field}
              error={getErrorText(field.name)}
            />
          ))}
        </FormFieldContainer>
      </Section>

      <Section>
        <FormCheckbox
          type="checkbox"
          name="otherShippingAddress"
          label="Die Lieferadresse weicht von der Rechnungsadresse ab"
        />
        {otherShippingAddress && (
          <>
            <h4>Lieferadresse</h4>
            <FormFieldContainer>
              {otherFields.map((field) => (
                <FormField
                  key={field.name}
                  {...field}
                  error={getErrorText(field.name)}
                />
              ))}
            </FormFieldContainer>
          </>
        )}
      </Section>

      <ButtonContainer>
        <Button type="submit" variant="contained">
          Weiter
        </Button>
      </ButtonContainer>
    </>
  );
};

export default PersonalData;
