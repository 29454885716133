import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";

import { useShopContext } from "context";
import { PageRedirect, Product } from "types";

const prodFilterKeys: (keyof Product)[] = [
  "slug",
  "name",
  "productNumber",
  "mainCategory",
  "subCategory",
  "description",
];

const itemsPerPage = 12;

export const useSearch = () => {
  const [results, setResults] = React.useState<Product[]>([]);
  const [products, setProducts] = React.useState<Product[]>([]);
  const [query, setQuery] = React.useState<string>("");

  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [totalPages, setTotalPages] = React.useState<number>(1);

  const { search } = useLocation();
  const navigate = useNavigate();

  const shop = useShopContext();

  React.useEffect(() => {
    const { begriff, sort } = queryString.parse(search);

    if (!begriff) {
      navigate("/", {
        replace: true,
        state: {
          type: "message",
          payload: "search",
        } as PageRedirect,
      });
      return;
    }

    const term = begriff as string;
    setQuery(term);

    const compare = term.toLowerCase();
    const filtered = shop.products.filter((prod) => {
      return prodFilterKeys.some((key) => {
        return (prod[key] as string).trim().toLowerCase().includes(compare);
      });
    });

    if (!filtered.length) {
      navigate("/", {
        replace: true,
        state: {
          type: "message",
          payload: "searchTerm",
        } as PageRedirect,
      });
      return;
    }

    if (sort) {
      const s = sort === "preis-ab" ? -1 : 1;
      filtered.sort((x, y) => {
        return s * (parseFloat(x.price) - parseFloat(y.price));
      });
    }

    setResults(filtered);
    setCurrentPage(1);
    setTotalPages(Math.ceil(filtered.length / itemsPerPage));
  }, [shop, search, navigate]);

  React.useEffect(() => {
    const idxLast = currentPage * itemsPerPage;
    const idxFirst = idxLast - itemsPerPage;
    const items = results.slice(idxFirst, idxLast);
    setProducts([...items]);
    window.scrollTo(0, 0);
  }, [results, currentPage]);

  return { query, products, currentPage, setCurrentPage, totalPages };
};
