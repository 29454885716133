import { CircularProgress, Grid } from "@mui/material";

const LoadingSpinner = () => {
  return (
    <Grid container gap={2} padding={3}>
      <CircularProgress size={20} />
      <span>Inhalte werden geladen...</span>
    </Grid>
  );
};

export default LoadingSpinner;
